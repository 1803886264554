import React, { useEffect, useState, useRef } from "react";
import { Marker } from "react-simple-maps";
import axios from 'axios';
import "../styles.css";

const radioCSV = 'https://wave-pulse-o5tct.ondigitalocean.app/api/radio-news/';
const claimsCSV = 'https://wave-pulse-o5tct.ondigitalocean.app/api/claims/';

const RadioMarkers = ({ zoom, showCoverage }) => {
  const [clusters, setClusters] = useState([]);
  const [hoveredCluster, setHoveredCluster] = useState(null);
  const [hoveredTooltipDims, setHoveredTooltipDims] = useState({ width: 0, height: 0 });
  const [claims, setClaims] = useState([]);
  const hoveredTooltipRef = useRef(null);

  useEffect(() => {
    axios.get(radioCSV)
      .then(radioData => {
        clusterMarkers(radioData.data);
      })
      .catch(error => {
        console.error("Error loading or parsing CSV data", error);
      });

    axios.get(claimsCSV)
      .then(claimsData => {
        // Structure claims as an object with CallSign as key and count as value
        const claimCounts = claimsData.data.reduce((acc, claim) => {
          acc[claim.Call_Sign] = (acc[claim.Call_Sign] || 0) + 1;
          return acc;
        }, {});
        setClaims(claimCounts);
      })
      .catch(error => {
        console.error("Error loading or parsing claims data", error);
      });
  }, []);

  useEffect(() => {
    if (hoveredCluster !== null && hoveredTooltipRef.current) {
      const bbox = hoveredTooltipRef.current.getBBox();
      setHoveredTooltipDims({ width: bbox.width, height: bbox.height });
    }
  }, [hoveredCluster]);

  const clusterMarkers = (data) => {
    const clusters = [];
    const nyCluster = [];
    const threshold = 0.2; 

    data.forEach((d, index) => {
      const latitude = parseFloat(d.Latitude);
      const longitude = parseFloat(d.Longitude);
      const state = d.State;
      const cityState = d.City;

      if (!isNaN(latitude) && !isNaN(longitude)) {
        if (cityState === "New York, NY") {
          nyCluster.push({ latitude, longitude, data: d });
        } else {
          let added = false;
          for (let cluster of clusters) {
            if (cluster[0].data.State === state) {
              for (let marker of cluster) {
                const dx = marker.longitude - longitude;
                const dy = marker.latitude - latitude;
                const distance = Math.sqrt(dx * dx + dy * dy);

                if (distance < threshold) {
                  cluster.push({ latitude, longitude, data: d });
                  added = true;
                  break;
                }
              }

              if (added) break;
            }
          }
          if (!added) {
            clusters.push([{ latitude, longitude, data: d }]);
          }
        }
      } else {
        console.warn(`Invalid latitude/longitude: ${latitude}, ${longitude} at index ${index}`);
      }
    });

    setClusters(nyCluster.length > 0 ? [nyCluster, ...clusters] : clusters);
  };

  const getCircleSize = (clusterLength) => {
    const baseSize = clusterLength > 1 ? 2.3 : 1.7;
    if (zoom > 5) {
      return baseSize * 3 / zoom; // Decrease size for higher zoom levels
    } else if (zoom > 2) {
      return baseSize * 2 / zoom; // Slightly decrease size for medium zoom levels
    } else {
      return baseSize / zoom; // Default size adjustment for lower zoom levels
    }
  };

  const getTooltipScale = () => {
    if (zoom > 5) {
      return 3; // Decrease size for higher zoom levels
    } else if (zoom > 2) {
      return 2; // Slightly decrease size for higher zoom levels
    } else {
      return 1; // Default size adjustment for lower zoom levels
    }
  };

  const isClaimed = (callSign) => {
    return claims[callSign] || 0; // Return the count of claims or 0 if not claimed
  };

  const getTrianglePoints = (radius) => {
    const height = radius * 2.5;
    const halfBase = radius * 1.5;
    return `${-halfBase},${halfBase} ${halfBase},${halfBase} 0,${-height}`;
  };

  return (
    <>
      {clusters.map((cluster, index) => {
        const avgLat = cluster.reduce((Avg, m) => Avg + m.latitude, 0) / cluster.length;
        const avgLon = cluster.reduce((Avg, m) => Avg + m.longitude, 0) / cluster.length;
        const radius = getCircleSize(cluster.length);
        const tooltipText = cluster.map(m => {
          const callSign = m.data["CallSign"];
          const claimCount = isClaimed(callSign);
          return `${callSign} - ${m.data.City} (Claims: ${claimCount})`;
        });
        const tooltipScale = getTooltipScale();
        const FMCallSigns = [];

        return (
          <Marker
            key={index}
            coordinates={[avgLon, avgLat]}
            onMouseEnter={() => setHoveredCluster(index)}
            onMouseLeave={() => setHoveredCluster(null)}
          >
            <g>
              {(showCoverage || hoveredCluster === index) &&
                !cluster.some(m => FMCallSigns.includes(m.data["CallSign"])) && (
                  <circle className="coverage" r={5} />
              )}

              {isClaimed(cluster[0].data["CallSign"]) ? (
                <polygon
                  points={getTrianglePoints(radius)}
                  className="marker"
                  style={{ fill: "#000000" }}
                />
              ) : (
                <circle
                  className="marker"
                  r={hoveredCluster === index ? radius * 0.7 : radius}
                  style={{ fill: "#555555" }}
                />
              )}

              {hoveredCluster === index && (
                <g>
                  <rect
                    width={hoveredTooltipDims.width + 8}
                    height={hoveredTooltipDims.height + 6}
                    className="radio-tooltip-background"
                    transform={`scale(${1 / tooltipScale})`}
                  />
                  <text
                    x={hoveredTooltipDims.width / 2 + 5}
                    y="1.2em"
                    textAnchor="middle"
                    className="radio-tooltip"
                    ref={hoveredTooltipRef}
                    transform={`scale(${1 / tooltipScale})`}
                  >
                    {tooltipText.map((line, i) => (
                      <tspan key={i} x={hoveredTooltipDims.width / 2 + 5} dy={i === 0 ? 0 : "1.2em"}>
                        {line}
                      </tspan>
                    ))}
                  </text>
                </g>
              )}
            </g>
          </Marker>
        );
      })}
    </>
  );
};

export default RadioMarkers;
